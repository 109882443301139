import { useEffect } from 'react'
import { DataLayerProvider } from 'swp-components/lib/contexts/data-layer'

import Newrelic from '@/components/newrelic-agent'
import Seo from '@/components/seo'
import useHomePageDataLayer from '@/hooks/data-layers/use-home-page'
import strapi from '@/strapi'

import NotFoundPage from '../404'

const docPage = ({ serverData }) => {
  let [pdfMng, headerScript] = strapi.parseContents(serverData.contents)

  // eslint-disable-next-line react-hooks/rules-of-hooks
  let { handleMessage, createScopeProps } = useHomePageDataLayer()
  let pdfContent = pdfMng[0]

  let pdfUrl = pdfContent.pdf.url
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (pdfContent == null || pdfMng.length <= 0) {
      return <NotFoundPage />
    }

    const iOS = () => {
      return (
        [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod',
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
      )
    }
    if (iOS()) {
      document.location.href = pdfUrl
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Newrelic />
      <DataLayerProvider handler={handleMessage}>
        <Seo seo={pdfContent.seo} script={headerScript.script} />
        <iframe
          src={pdfUrl}
          title={pdfContent.name}
          style={{ width: '100%', height: '100%', position: 'absolute' }}
        ></iframe>
      </DataLayerProvider>
    </>
  )
}

export default docPage

export const getServerData = async ({ params, pageContext }) => {
  let contents = await strapi.getContents(
    [`pdf-managements?slug=${params.slug}`, 'header-script'],
    pageContext.locale
  )

  return {
    props: { contents, slug: params.slug, locale: pageContext.locale },
  }
}
