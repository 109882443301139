const digestMessage = async message => {
  let messageUint8 = new TextEncoder().encode(message)
  let hashBuffer = await crypto.subtle.digest('SHA-256', messageUint8)
  let hashArray = Array.from(new Uint8Array(hashBuffer))
  let hashHex = hashArray
    .map(byte => byte.toString(16).padStart(2, '0'))
    .join('')

  return hashHex
}

export default digestMessage
