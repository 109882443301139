import { Helmet } from 'react-helmet'

const NewrelicAgent = ({ ...props }) => {

  return (
    <>
      {process.env.GATSBY_NEWRELIC && process.env.GATSBY_NEWRELIC === 'enable' && (
        <Helmet>
          <script type="text/javascript" src="/newrelic-agent.js" />
        </Helmet>
      )}
    </>
  )
}

export default NewrelicAgent
