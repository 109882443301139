import digestMessage from '@/util/digest-message'

const handleCommonMessage = async ({ selector, context }, pushMessage) => {
  if (/^Header\/AppBar.(mobile)?link#\d+$/i.test(selector)) {
    pushMessage({
      event: 'header_category',
      page_group: 'navigation',
      header_category_1: context.item.name,
      header_category_2: 'none',
      header_category_3: 'none',
    })
  }

  if (/^Header\/AppBar.link#\d+:\d+$/i.test(selector)) {
    pushMessage({
      event: 'header_category',
      page_group: 'navigation',
      header_category_1: context.item.name,
      header_category_2: context.detail.name,
      header_category_3: 'none',
    })
  }

  if (/^Header\/MenuItem.(mobile|desktop)Link#\d+$/i.test(selector)) {
    pushMessage({
      event: 'header_category',
      page_group: 'navigation',
      header_category_1: context.service.name,
      header_category_2: 'none',
      header_category_3: 'none',
    })
  }

  if (/^Header\/MenuItem.(mobile|desktop)Link#\d+:\d+$/i.test(selector)) {
    pushMessage({
      event: 'header_category',
      page_group: 'navigation',
      header_category_1: context.service.name,
      header_category_2: context.header.name,
      header_category_3: 'none',
    })
  }

  if (/^Header\/MenuItem.(mobile|desktop)Link#\d+:\d+:\d+$/i.test(selector)) {
    pushMessage({
      event: 'header_category',
      page_group: 'navigation',
      header_category_1: context.service.name,
      header_category_2: context.header.name,
      header_category_3: context.detail.name,
    })
  }

  if (
    selector === 'Header/AppBar.mobileSignInButton' ||
    selector === 'Header/AppBar.desktopSignInButton'
  ) {
    pushMessage({
      event: 'header_category',
      page_group: 'navigation',
      header_category_1: 'sign_in',
      header_category_2: 'user',
      header_category_3: 'none',
    })
  }

  if (
    selector === 'Header/AppBar.desktopSignOutButton' ||
    selector === 'Header/UserMenu.signOutButton'
  ) {
    pushMessage({
      event: 'header_category',
      page_group: 'navigation',
      header_category_1: 'sign_out',
      header_category_2: 'user',
      header_category_3: 'none',
    })
  }

  if (selector === 'Header/AppBar.desktopSignUpButton') {
    pushMessage({
      event: 'header_category',
      page_group: 'navigation',
      header_category_1: 'sign_up',
      header_category_2: 'user',
      header_category_3: 'none',
    })
  }

  if (
    selector === 'Header/AppBar.desktopManageAccountButton' ||
    selector === 'Header/UserMenu.manageAccountButton'
  ) {
    pushMessage({
      event: 'header_category',
      page_group: 'navigation',
      header_category_1: 'account',
      header_category_2: 'user',
      header_category_3: 'none',
    })
  }

  if (selector === 'Header/LanguageSwitch.enButton') {
    pushMessage({
      event: 'header_category',
      page_group: 'navigation',
      header_category_1: 'en',
      header_category_2: 'internationalization',
      header_category_3: 'none',
    })
  }

  if (selector === 'Header/LanguageSwitch.thButton') {
    pushMessage({
      event: 'header_category',
      page_group: 'navigation',
      header_category_1: 'th',
      header_category_2: 'internationalization',
      header_category_3: 'none',
    })
  }

  if (/^Footer\/(Footer|Detail).link#\d+$/i.test(selector)) {
    pushMessage({
      event: 'footer_category',
      page_group: 'navigation',
      footer_category_1: context.item.name,
      footer_category_2: 'none',
    })
  }

  if (
    /^Footer\/(Footer|Detail).(mobile|desktop)?link#\d+:\d+$/i.test(selector)
  ) {
    pushMessage({
      event: 'footer_category',
      page_group: 'navigation',
      footer_category_1: context.item.name,
      footer_category_2: context.detail.name,
    })
  }

  if (selector === 'ChatShop/ChatShop.launcher') {
    pushMessage({
      event: 'chat_and_shop',
      page_group: 'navigation',
      button: 'chat_and_shop',
    })
  }

  if (selector === 'ChatShop/LiveChatMenu.button') {
    pushMessage({
      event: 'chat_and_shop',
      page_group: 'navigation',
      button: 'live_chat',
    })
  }

  if (selector === 'ChatShop/CallbackMenu.button') {
    pushMessage({
      event: 'chat_and_shop',
      page_group: 'navigation',
      button: 'call_back',
    })
  }

  if (selector === 'ChatShop/CallCenterMenu.button') {
    pushMessage({
      event: 'chat_and_shop',
      page_group: 'navigation',
      button: 'call_center',
    })
  }

  if (selector === 'ChatShop/LineMenu.button') {
    pushMessage({
      event: 'chat_and_shop',
      page_group: 'navigation',
      button: 'line',
    })
  }

  if (selector === 'ChatShop/MariMenu.button') {
    pushMessage({
      event: 'chat_and_shop',
      page_group: 'navigation',
      button: 'mari',
    })
  }

  if (selector === 'ChatShop/ContactModal@submitFormSuccess') {
    pushMessage({
      event: 'click_call_back',
      page_group: 'navigation',
      hash_phone_number: await digestMessage(context.inputs.phoneNumber),
      lead_id: 'none',
    })
  }
}

const usePageDataLayer = () => {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || []
  }

  let pushMessage = message => {
    window.dataLayer.push(message)
  }

  let createHandler = handlePageMessage => {
    return message => {
      handlePageMessage(message, pushMessage)
      handleCommonMessage(message, pushMessage)
    }
  }

  let createScopeProps = (namespace, scopeContext) => {
    let wrapSelector = selector => `${namespace}/${selector}`
    let wrapContext

    if (scopeContext) {
      wrapContext = context => ({ ...context, ...scopeContext })
    }

    return { wrapSelector, wrapContext }
  }

  return { createHandler, createScopeProps }
}

export default usePageDataLayer
