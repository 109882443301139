import { I18nContext } from 'gatsby-plugin-i18n'
import { pick, pickBy } from 'lodash-es'
import { useContext } from 'react'
import { Helmet } from 'react-helmet'

const Seo = ({ seo, script }) => {
  let { locale: lang } = useContext(I18nContext)

  if (!seo) {
    seo = { title: '', seoMeta: [] }
  }
  let meta =
    seo.seoMeta?.map(meta =>
      pickBy(pick(meta, ['name', 'property', 'content']))
    ) ?? []

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={seo.title}
      link={[
        {
          rel: 'icon',
          href: '/favicon.ico',
        },
      ]}
      meta={[
        {
          name: 'description',
          content: seo.metaDescription,
        },
      ].concat(meta)}
    >
      <script>{script}</script>
    </Helmet>
  )
}

export default Seo
